@import url(../Fonts/fonts.css);

.tankSettingsMenu {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    order: -1;
    margin-bottom: 25px;
    width: 90%;
    max-width: 433px;
}

@media only screen and (min-width: 768px) {
    .tankSettingsMenu {
        order: 1;
        margin-bottom: 0px;
    }
}

.fishLevelBar {
    display: flex;
    cursor: pointer;
    margin-left: 10px;
    align-self: center;
}

.fishLevelBar h1 {
    font-family: "SVBold";
    width: 30px;
    margin: 1px 5px;
    margin-right: 0px;
    color: #af7645;
    text-shadow: 3px 0px 0 black, -3px 0px 0 black, 0px 3px 0 black, 0px -3px 0 black;
    font-size: 1.5em;
}

.fishLevelBar h1.foodLevelText {
    color: #67ab67;
}

.fishingLevelUnit {
    width: 15px;
    height: 21px;
    background-color: #c97d1e;
    margin: 2px;
    position: relative;
    border-style: solid;
    border-width: 3px;
    border-radius: 5px;
    border-color: #a96a29 #a96a29 #8a521b #8a521b;

}

.fishingLevelUnit::before {
    background: none;
    content: "";
    position: absolute;
    border-style: solid;
    border-width: 3px;
    border-color: #c99033;
    border-radius: 5px;
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
}

.fishingLevelUnit::after {
    background: none;
    content: "";
    position: absolute;
    border-style: solid solid none none;
    border-width: 3px;
    border-color: #ac5e1e;
    border-radius: 5px;
    display: block;
    position: absolute;
    top: 3px;
    left: 3px;
    right: 3px;
    bottom: 3px;
}

.fishingLevelUnit.major {
    width: 33px;
}

.fishingLevelUnit.active {
    background-color: #dd003b;
    border-color: #960027 #960027 #63003e #63003e;
}

.fishingLevelUnit.active::before {
    border-color: #ff6948 #ff6948 #bc004a #bc004a;
}

.fishingLevelUnit.active::after, .fishingLevelUnit.double::after {
    border-style: none;
}

.fishingLevelUnit.major.active {
    background-color: #ffa400;
    border-color: #960027;
}

.fishingLevelUnit.major.active::before {
    border-color: #ffc62e #ffc62e #e06a00 #e06a00;
}

.fishingLevelUnit.double {
    background-color: #00a005;
    border-color: #005904 #005904 #0c2600 #0c2600;
}

.fishingLevelUnit.double::before {
    border-color: #37c27a #37c27a #0c7f00 #0c7f00;
}

.foodmenu, .tackleMenu{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.foodmenu img, .tackleMenu img {
    width: 50px;
    height: 50px;
    image-rendering: pixelated;
    margin: 5px;
    border: solid 2px #ffcb7b;
    cursor: pointer;
}

.foodmenu > div {
    position: relative;
    width: 60px;
    height: 60px;
}

.foodmenu > div .quality {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 30px;
    top: 35px;
    border: none;
}

.foodmenu .food_selected, .tackleMenu .tackle_selected {
    background-color: #d49231;
    border-radius: 15%;
    border: solid 2px #bd7d1e;
}

.tankSettingsMenu button {
    margin-top: 50px;
    align-self: center;
    width: 100px;
    height: 40px;
    font-family: "SVBold";
    font-size: 1.5em;
    background-color: #dc7b05;
    border-color: #5b2b2a;
    text-shadow: 2px 2px 0px black;
    color: #e5ceb3;
    border-radius: 10%;
}

@media only screen and (min-width: 768px) {
    .fishingLevelUnit {
        width: 20px;
        height: 28px;
        border-width: 4px;
    }

    .fishingLevelUnit::before {
        border-width: 4px;
    }

    .fishingLevelUnit::after {
        border-width: 4px;
        top: 4px;
        bottom: 4px;
        right: 4px;
        left: 4px;
    }

    .fishingLevelUnit.major {
        width: 44px;
    }

    .fishLevelBar h1 {
        font-size: 2.3em;
        margin-left: 20px;
        width: 30px;
    }
}

.tackleInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    border: ridge 5px #fa9305;
    height: 70px;
    width: 90%;
    border-radius: 10px;
    margin-bottom: 20px;
}

.tackleInfo p {
    font-family: "SVThin";
    text-align: center;
    margin: 0px;
}
.tackleInfo h1 {
    font-family: "SVBold";
    text-align: center;
    margin: 0px;
    font-size: 1.2em;
}

@media only screen and (min-width: 768px) {
    .tackleInfo {
        width: 433px;
    }
}