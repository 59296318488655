@import url(../Fonts/fonts.css);

.background {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.settingsModal {
    max-width: 80%;
    max-height: 70vh;
    overflow-y: auto;
    width: 500px;
    background-color: #ffcb7b;
    border: 16px solid transparent;
    border-image: url('../Art/modal-border.png') 16 round;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: left;
    font-family: "SVThin";
    padding: 15px;
}

.settingsModal h1 {
    font-family: "SVBold";
    padding: 0px;
    margin: 0px;
    margin-bottom: 25px;
}

.settingsModal h2 {
    padding: 0px;
    margin: 0px;
    text-align: left;
}

.settingsModal li {
    margin: 3px 10px;
    text-align: left;
}

.settingsModal ul {
    margin: 0px;
    font-size: 1.2em;
}

.toggleButtonContainer {
    height: 20px; 
    width: 20px;
    
    background-repeat: no-repeat;
    background-size: 20px 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    align-self: baseline;
    margin-right: 30px;
}

.toggleButtonContainer:not(.toggleButtonContainerToggled) {
    
    background-image: url('../Art/box-unchecked.png');
}

.toggleButtonContainer.toggleButtonContainerToggled {
    background-image: url('../Art/box-checked.png');
}

.setting {
    display: flex;
    /* align-self: left; */
    user-select: none;
    margin-bottom: 10px;
}

.settingDesc {
    width: 90%;
}

.settingDesc h3 {
    margin: 0px;
    font-weight: 100;
}

.settingDesc h2 {
    margin: 0px;
    font-size: 1.4em;
    font-weight: bold;
}