@import url(./Fonts/fonts.css);

.Page {
    background-color: #1b284b;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    height: 100%;
}

header {
    width: 100%;
    display: flex;
    justify-content: center;
}

.toolbar {
    display: flex;
    width: 300px;
    justify-content: center;
    background-color: #ffcb7b;
    border-radius: 10px;
    border-color: #fa9305;
    border-width: 5px;
    border-style: ridge;
}


.toolbar > div {
    display: flex;
    position: relative;
    width: calc(100%/6);
    aspect-ratio: 1 / 1;
    align-items: center;
    justify-content: center;
    border-style: solid;
    border-width: 4px;
    border-right-color: #d68f54;
    border-top-color: #d68f54;
    border-left-color: #ffe4a1;
    border-bottom-color: #ffe4a1;
}

.toolbar > div > img {
    width: 80%;
    height: 80%;
    image-rendering: pixelated;
    cursor: pointer;
}

.toolbar > div > p {
    position: absolute;
    margin: 0;
    top: -2px;
    left: 0px;
    font-family: "SVThin";
    color: #696969;
    font-size: 15px;
}

@media only screen and (min-width: 768px) {
    .toolbar {
        width: 100%;
        max-width: 700px;
        flex-direction: row;
    }
    .toolbar > div {
        width: calc(100%/12);
    }
}

.infoDiv {
    margin-left: 10px;
    align-self: flex-end;
    cursor: pointer;
}

.App {
    display: flex;
    width: 100%;
    min-height: 90vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.menuButtons{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.menuButtons button {
    margin: 20px;
    padding: 30px;
    font-family: "SVBold";
    font-size: 1.5em;
    background-color: #dc7b05;
    border-color: #5b2b2a;
    display: inline-block;
}

.menuButton {
    display: flex;
    background-color: #dc7b05;
    border-width: 4px;
    border-color: #5b2b2a;
    border-style: solid;
    align-items: center;
    margin: 20px;
    padding: 20px 30px;
    cursor: pointer;
}

.menuButton:hover {
    background-color: #ad6104;
}

.menuButton img {
    width: 50px;
    height: 50px;
    image-rendering: pixelated;
    margin-right: 20px;
}

.menuButton h1 {
    margin: 0px;
    font-family: "SVBold";
    font-size: 2em;
    text-shadow: 2px 2px 0px black;
    color: #e5ceb3;
}

