@import url('../Fonts/fonts.css');

.menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    order: 1;
    margin-top: 50px;
}

form {
    margin-bottom: 10px;
    margin-left: 10px;
    width: 85%;
}

form > * {
    vertical-align: middle;
}

input[type=text] {
    width: 75%;
    height: 50px;
    background-color: #f9ba66;
    border-color: #f9ba66;
    outline: none;
    font-size: 2em;
    font-family: "SVBold";
    margin-right: 10px;
}

input[type=text]:focus {
    background-color: #d6861e;
    border-color: #d6861e;
}

.menu button {
    height: 40px;
    width: 60px;
    background-color: #dc7b05;
    border-color: #5b2b2a;
    display: inline-block;
    font-family: "SVBold";
    font-size: 1em;
}

@media only screen and (min-width: 768px) {
    .menu {
        order: 0;
        margin-top: 0px;
    }
}