@import url(../Fonts/fonts.css);

.background {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.modal {
    max-width: 80%;
    max-height: 70vh;
    overflow-y: auto;
    width: 500px;
    background-color: #ffcb7b;
    border-color: #fa9305;
    border-width: 8px;
    border-style: ridge;
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 0 20px 0 20px;
}

.modal h1 {
    font-family: "SVBold";
    padding: 0px;
    margin: 0px;
    align-self: center;
}

.modal h2 {
    font-family: "SVThin";
    padding: 0px;
    margin: 0px;
    align-self: center;
}

.modal h3 {
    font-family: "SVThin";
    padding: 0px;
    margin: 0px;
    align-self: center;
}

.modal img {
    width: 50px;
    height: 50px;
    image-rendering: pixelated;
}

.resultImages {
    align-self: center;
    display: flex;
    width: 60%;
    justify-content: space-evenly;
}

hr {
    width: 100%;
    color: #fa9305;
    background-color: #da8106;
    border-style: none none solid none;
    border-width: px;
    border-color:#995a02;
    margin-top: 20px;
    height: 2px;
}

button {
    width: 150px;
    height: 25px;
    align-self: center;
    border: solid 1px;
    margin: 5px;
    font-size: 1.1em;
    font-family: "SVThin";
    font-weight: bold;
    text-shadow: 1px 1px 0px black;
    color: #e5ceb3;
    background-color: #dc7b05;
    border-color: #5b2b2a;
    cursor: pointer;
}

button:hover {
    background-color: #ad6104;
}

.statLine {
    display: flex;
    align-items: center;
    font-family: "SVThin"
}

.statLine > h3 {
    display: inline;
    flex: 0 0 auto;
    width: 20px;
    height: 1.17em;
    margin: 0;
    padding-top: 4px;
}

.statBar {
    display: flex;
    align-items: center;
    height: 1.17em;
    margin: 0px 5px 0px 5px;
    background-color: #598f0a;
}

/* .statBar > h2 {
    align-self: left;
} */

.statBar > h3 {
    display: inline;
    color: #fff;
    margin: 0;
    margin-left: auto;
    margin-right: 2px;
    margin-top: 3px;
}

.dayStats {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    align-self: center;
    width: 100%;
    margin-bottom: 10px;
    font-family: "SVThin"
}

.dayStats > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 50px;
    height: 55px;
}

.dayStats > div > h2 {
    margin: 0;
}

.dayStats > div > h4 {
    margin: 0;
    text-align: center;
}