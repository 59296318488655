@import url("../Fonts/fonts.css");

.fish_container {
    padding: 15px 15px;
    width: 95%;
    display: grid;
    place-items: center;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(7, 1fr);
    grid-auto-flow: row;
    row-gap: 15px;
    /* background-color: #dda817; */
}


@media only screen and (min-width: 768px) {
    .fish_container {
        width: 500px;
        grid-template-columns: repeat(8, 1fr);
    }
}

.fishContainer {
    position: relative;
    width: 50px;
    height: 50px;
}

.fish_icon {
    image-rendering: pixelated;
    width: 50px;
    height: 50px;
    position: absolute;
    cursor: pointer;
}

.selected {
    image-rendering: pixelated;
    width: 50px;
    height: 50px;
    transform: scale(1.2);
    mask-repeat: no-repeat;
    mask-size: 100%;
    background-color: rgb(50, 200, 50);
    position: absolute;
}

.shadow {
    image-rendering: pixelated;
    width: 50px;
    height: 50px;
    top: 3px;
    right: 3px;
    /* transform: scale(1.1); */
    mask-repeat: no-repeat;
    mask-size: 100%;
    background-color: #a17949;
    position: absolute;
}

.fish_container .quality {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 30px;
    top: 35px;
    border: none;
    image-rendering: pixelated;
}
