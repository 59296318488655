@import url(../Fonts/fonts.css);

.background {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.navBarModal {
    max-width: 100%;
    max-height: 80vh;
    width: 200px;
    background-color: #ffcb7b;
    border-color: #fa9305;
    border-width: 8px;
    border-style: ridge;
    font-family: "SVThin";
    padding-left: 15px;
    display: grid;
    grid-template-columns: 25% 75%;
    grid-template-rows: repeat(5, 20%);
    justify-items: start;
    align-items: center;
    /* padding-right: 15px; */
}

.navBarModal > img {
    height: 30px;
    width: 30px;
    image-rendering: pixelated;
}

.navBarModal > button {
    grid-column: 1 / span 2;
    justify-self: center;
}

.navBarModal > h2 {
    justify-self: start;
    align-self: center;
    margin: 10px 0px;
    margin-top: 25px;
}