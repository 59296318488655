@import url(../Fonts/fonts.css);

.background {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.helpModal {
    max-width: 100%;
    max-height: 80vh;
    width: 600px;
    background-color: #ffcb7b;
    border-color: #fa9305;
    border-width: 8px;
    border-style: ridge none;
    display: flex;
    flex-direction: column;
    align-items: left;
    font-family: "SVThin";
    padding-left: 15px;
    /* padding-right: 15px; */
}


@media only screen and (min-width: 600px) {
    .helpModal {
        border-style: ridge;
    }
}

.helpInfo {
    /* width: 100%; */
    overflow-y: auto;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    align-items: left;
}

.helpInfo .Row, .helpInfo .RowNames {
    align-self: center;
}

.helpModal h1 {
    font-family: "SVBold";
    padding: 0px;
    margin: 0px;
}

.helpModal h2 {
    padding: 0px;
    margin: 0px;
    text-align: left;
}

.helpModal li {
    margin: 3px 10px;
    text-align: left;
}

.helpModal ul {
    margin: 0px;
    font-size: 1.2em;
}

.helpSetting {
    display: flex;
    align-self: center;
    margin: 0px;
}

.helpSetting h3 {
    margin: 0px;
    margin-left: 10px;
    margin-top: 2px;
    align-self: center;
    user-select: none;
}
